import { QueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import _ from "lodash"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 120000,
      retry: (failureCount, error) => {
        if (failureCount > 2) return false

        if (error instanceof AxiosError) {
          // Do not retry on authorization errors
          return ![401, 403].includes(error.response?.status ?? 0)
        }

        return true
      },
    },
    mutations: {
      onError: () => {
        _.noop()
      },
    },
  },
})
